import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";

const Hero = ({ banners }) => {
  const settings = {
    dots: false,
    arrows: false,
    speed: 1100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };

  return (
    <section className="gauto-slider-area fix">
      <Slider {...settings}>
        {
          banners.length > 0
            ? banners.map((item, index) => (
              <div className="slide" key={index}>
                <Image
                  src={item.banner.imageUrl}
                  alt="banner"
                  width={3000}
                  height={450}
                  priority={true}
                  className="gauto-main-slide"
                  style={{
                    maxWidth: '100%',
                    // height: '330px',
                  }} />
              </div>
            ))
            : null
        }
      </Slider>
    </section>
  );
};

export default Hero;
