import Image from "next/image";
import { useEffect, useState } from "react"

export const FallBackImage = ({ src, alt, ...rest }) => {
    const [imgSrc, setImgSrc] = useState(src);
    useEffect(() => { setImgSrc(src); }, [src]);

    return (
        <Image
            {...rest}
            src={imgSrc ? imgSrc : '/img/no-image.jpeg'}
            alt={alt}
            onError={() => { setImgSrc('/img/no-image.jpeg') }} />
    )
}