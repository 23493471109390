import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Service = () => {
  const settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="gauto-service-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h2>Nossos serviços</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="service-slider" {...settings}>
              <div className="slide">
                <div className="single-service servicebox aboutbox">
                  {/* <span className="service-number">01 </span> */}
                  <div className="service-icon">
                    <img src={'../img/vendaveiculo.jpg'} style={{marginTop: '3px'}} alt="city trasport" />
                  </div>
                  <div className="service-text">
                    <a href="/venda-seu-veiculo">
                      <h3>Compramos seu veículo</h3>
                    </a>
                    <p>
                      Compramos seu veículo com os melhores valores do mercado .
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service servicebox aboutbox">
                  {/* <span className="service-number">02 </span> */}
                  <div className="service-icon">
                    <img src={'../img/financialicon.png'} alt="airport trasport" />
                  </div>
                  <div className="service-text">
                    <a href="/financiamento">
                      <h3>Financie seu veículo</h3>
                    </a>
                    <p>
                      Precisando de dinheiro? Clique aqui e preencha a nossa ficha para que seja realizada sua análise de financiamento.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service servicebox aboutbox">
                  {/* <span className="service-number">03 </span> */}
                  <div className="service-icon serviceicon">
                    <img src={'../img/phone-volume-solid.jpg'} style={{height: '40px' , marginTop: '15px'}} alt="hospital trasport" />
                  </div>
                  <div className="service-text">
                    <a href="/contato">
                      <h3>Contato</h3>
                    </a>
                    <p>
                      Contate-nos para mais dúvidas referente ofertas e serviços , nossos atendentes estão á disposição para te atender da melhor forma.
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Service;
