import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaQuoteRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = () => {
  const settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="gauto-testimonial-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>Excelência em Atendimento</h4>
              <h2>O Que Nossos Clientes Dizem?</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="testimonial-slider" {...settings}>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                    Troquei meu carro algumas vezes, e fui atendido pelo próprio Sr Gilvan, carros excelentes de ótima procedência nunca tive problema, pelo contrário só alegrias ! Recomendo muito a agência de veículos Gilvan veículos, vc vai ser muito bem atendido!
                    </p>
                    <div className="testimonial-meta">
                      {/* <div className="client-image">
                        <img src={'../../../img/testimonial.jpg'} alt="testimonial" />
                      </div> */}
                      <div className="client-info">
                        <h3>Rodinei Aparecido Felicio</h3>
                        <p>Cliente da Loja</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                    Super gentil..pagou certinho ,vendi meu Palio attractive e me pagaram tudo certinho !!! Amei o atendimento foi super rápido !!
                    </p>
                    <div className="testimonial-meta">
                      {/* <div className="client-image"> */}
                        {/* <img src={'../../../img/testimonial-2.jpg'} alt="testimonial" /> */}
                      {/* </div> */}
                      <div className="client-info">
                        <h3>Monica Francisco</h3>
                        <p>Cliente da Loja</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                    Comprei um carro na Gilvan Veículos a um ano atrás, estou muito satisfeito, fui muito bem atendido, vendedor transparente e honesto eu recomendo, o meu próximo veículo com certeza eu comprarei com eles.
                    </p>
                    <div className="testimonial-meta">
                      {/* <div className="client-image"> */}
                        {/* <img src={'../../../img/testimonial.jpg'} alt="testimonial" /> */}
                      {/* </div> */}
                      <div className="client-info">
                        <h3>Pedro Albuquerque</h3>
                        <p>Cliente da Loja</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                    Excelente local para compra e venda de veículos. Fui muito bem atendido e me senti confortável em todos os pontos do processo. Recomendo!
                    </p>
                    <div className="testimonial-meta">
                      {/* <div className="client-image"> */}
                        {/* <img src={'../../../img/testimonial.jpg'} alt="testimonial" /> */}
                      {/* </div> */}
                      <div className="client-info">
                        <h3>Geraldo Martins</h3>
                        <p>Cliente da Loja</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonial;
