import { FallBackImage } from "@/components/default/fallback-image";
import React from "react";
import { Container, Row, Col, } from "react-bootstrap";
import { FaGasPump, FaCarAlt } from "react-icons/fa";
import { NumericFormat } from "react-number-format";

const HotOffers = ({ ads }) => {
  return (
    <section className="gauto-offers-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>Confira abaixo nossas</h4>
              <h2>Últimas Ofertas</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="car-listing-right">
              <div className="car-grid-list">
                <Row>
                  {
                    ads.items.map((item, index) =>
                      <Col xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                        <div className="single-offers">
                          <div className="offer-image-hot-offers">
                            <a href={`anuncio/${item.category}/${`${item.brand.split(' ').join('-').toLowerCase()}-${item.model.split(' ').join('-').toLowerCase()}`}/${item.yearModel}/${item.id}`}>
                              <FallBackImage
                                height={200}
                                width={276}
                                alt='carro'
                                src={item.mainPhoto ? item.mainPhoto : null} />
                            </a>
                          </div>
                          <div className="offer-text">
                            <a href={`anuncio/${item.category}/${`${item.brand.split(' ').join('-').toLowerCase()}-${item.model.split(' ').join('-').toLowerCase()}`}/${item.yearModel}/${item.id}`}>
                              <h2
                                style={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  display: 'inline-block',
                                  width: '100%'
                                }}
                                title={`${item.brand} ${item.model}`}>{`${item.brand} ${item.model}`}</h2>
                              <p
                                style={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  display: 'inline-block',
                                  width: '100%'
                                }}
                                title={item.version}>{item.version}</p>
                            </a>
                            <h4>
                              <span>
                                {
                                  item.promotionalPrice
                                    ? <h5 className="price">
                                      {
                                        item.price ?
                                          <NumericFormat
                                            style={{ color: 'black', fontSize: '14px', textDecoration: 'line-through' }}
                                            value={item.price ?? 0}
                                            displayType={'text'}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            prefix='De R$ ' />
                                          : 'consulte-nos'
                                      }
                                    </h5>
                                    : null
                                }
                                <h5 className="price">
                                  {
                                    item.price ?
                                      <NumericFormat
                                        value={item.promotionalPrice ? item.promotionalPrice : item.price}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={`${item.promotionalPrice ? 'Por R$ ' : 'R$ '}`} />
                                      : 'consulte-nos'
                                  }
                                  </h5>
                              </span>
                            </h4>
                            <ul>
                              <li title={item.fuel}>
                                <FaGasPump />
                                {item.fuel}
                              </li>
                              <li title={item.transmission}>
                                <FaCarAlt />
                                {item.transmission}
                              </li>
                            </ul>
                          </div>
                          <div className="offer-action">
                            <a href={`anuncio/${item.category}/${`${item.brand.split(' ').join('-').toLowerCase()}-${item.model.split(' ').join('-').toLowerCase()}`}/${item.yearModel}/${item.id}`} className="offer-btn" >ver detalhes</a>
                          </div>
                        </div>
                      </Col>
                    )
                  }
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HotOffers;
