import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { fetchGetAdsFilters, fetchGetAdsFilterModelByBrand } from "../../../../services/api";

const FindCar = () => {
  const router = useRouter();
  const { handleSubmit, register, setValue } = useForm();
  const [filters, setFilters] = useState({ isLoading: true, brands: [], models: [], yearsModel: [], transmissions: [], fuels: [] });
  const [filterBrand, setFilterBrand] = useState({isLoading: false, brand: ""});

  useEffect(() => {
    if (filters.isLoading) {      
      fetchGetAdsFilters({ condition: null })
        .then(res => {
          if (res) { setFilters({ isLoading: false, ...res }); }
        })
        .catch(() => { setFilters({ ...filters, isLoading: false }); });
    }
  }, [filters]);

  useEffect(() => {
    if (filterBrand.isLoading) {      
      fetchGetAdsFilterModelByBrand({brand: filterBrand.brand })
        .then(res => {         
          if (res) { setFilters(prevFilters => ({ ...prevFilters, isLoading: false, models: res.models })); }
          setFilterBrand({ ...filterBrand, isLoading: false });
        })
        .catch(() => { setFilterBrand({ ...filterBrand, isLoading: false }); });
    }
    setValue('ddlModel', "");
  }, [filterBrand, filters, setValue]);
  


  const handleBrandChange = (event) => {
    let idBrand = event.target.value;    
    if (idBrand)
    setFilterBrand({ ...filterBrand, isLoading: true, brand: idBrand});
    else setFilters({ ...filters, isLoading: true }); 
  }

  const onSubmit = (data) => {
    if (data.ddlBrand) router.query.marca = data.ddlBrand;
    if (data.ddlModel) router.query.modelo = data.ddlModel;
    if (data.ddlPriceFrom) router.query.preco_de = data.ddlPriceFrom;
    if (data.ddlPriceTo) router.query.preco_ate = data.ddlPriceTo;
    router.push(`/estoque?${new URLSearchParams(router.query)}`);
  }

  return (
    <section className="gauto-find-area">
      <Container>
        <Row>
          <Col md={12}>
            <div className="find-box">
              <Row className="align-items-center">
                <Col md={12}>
                  <div className="find-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={5}>
                          <Row>
                            <Col md={6}>
                              <label>Marca</label>
                              <p>
                                <select
                                  className="form-select"
                                  {...register("ddlBrand")}
                                  onChange={handleBrandChange}>
                                  <option value={''}></option>
                                  {filters.brands?.map((item, index) => <option value={item.name} key={index}>{item.name}</option>)}
                                </select>
                              </p>
                            </Col>
                            <Col md={6}>
                              <label>Modelo</label>
                              <p>
                                <select
                                  className="form-select"
                                  {...register("ddlModel")}>
                                  <option value={''}></option>
                                  {filters.models.map((item, index) => <option value={item.name} key={index}>{item.name}</option>)}
                                </select>
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={5}>
                          <Row>
                            <Col md={6}>
                              <label>Valor De</label>
                              <p>
                                <select className="form-select" {...register("ddlPriceFrom")}>
                                  <option value=''></option>
                                  <option value='10000'>R$ 10.000,00</option>
                                  <option value='20000'>R$ 20.000,00</option>
                                  <option value='30000'>R$ 30.000,00</option>
                                  <option value='40000'>R$ 40.000,00</option>
                                  <option value='50000'>R$ 50.000,00</option>
                                  <option value='60000'>R$ 60.000,00</option>
                                  <option value='70000'>R$ 70.000,00</option>
                                  <option value='80000'>R$ 80.000,00</option>
                                  <option value='90000'>R$90.000,00</option>
                                  <option value='100000'>R$ 100.000,00</option>
                                  <option value='200000'>R$ 200.000,00</option>
                                  <option value='300000'>R$ 300.000,00</option>
                                  <option value='400000'>R$ 400.000,00</option>
                                  <option value='500000'>R$ 500.000,00</option>
                                </select>
                              </p>
                            </Col>
                            <Col md={6}>
                              <label>Valor Até</label>
                              <p>
                                <select className="form-select"{...register("ddlPriceTo")}>
                                  <option value=''></option>
                                  <option value='10000'>R$ 10.000,00</option>
                                  <option value='20000'>R$ 20.000,00</option>
                                  <option value='30000'>R$ 30.000,00</option>
                                  <option value='40000'>R$ 40.000,00</option>
                                  <option value='50000'>R$ 50.000,00</option>
                                  <option value='60000'>R$ 60.000,00</option>
                                  <option value='70000'>R$ 70.000,00</option>
                                  <option value='80000'>R$ 80.000,00</option>
                                  <option value='90000'>R$90.000,00</option>
                                  <option value='100000'>R$ 100.000,00</option>
                                  <option value='200000'>R$ 200.000,00</option>
                                  <option value='300000'>R$ 300.000,00</option>
                                  <option value='400000'>R$ 400.000,00</option>
                                  <option value='500000'>R$ 500.000,00</option>
                                </select>
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={2}>
                          <label></label>
                          <div className="single-contact-btn">
                            <button type="submit">
                              Ver Ofertas
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FindCar;
