import Head from "next/head";
import FindCar from "@/components/home/find-car";
import Hero from "@/components/home/hero";
import HotOffers from "@/components/home/hot-offers";
import Service from "@/components/home/services";
import Testimonial from "@/components/home/testimonial";
import Footer from "@/components/layout/footer";
import Header from "@/components/layout/header";
import { fetchGetAdsByCompany, fetchGetBanners } from "../../services/api";

export default function Home({ companyGeneralData, adsLastOffers, banners }) {
  companyGeneralData = JSON.parse(companyGeneralData);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Revenda de veículos multimarcas, novos e semi-novos. Veículos com procedência e garantia." />
        <meta name="author" content="https://bndv.com.br" />
        <title>{`${(companyGeneralData.company.nickName).toUpperCase()} - ${companyGeneralData.company.city ? companyGeneralData.company.city : ''}`}</title>
      </Head>
      <>
        <Header company={companyGeneralData.company} />
        <Hero banners={banners} />
        <FindCar />
        <HotOffers ads={adsLastOffers} />
        <Service />
        <Testimonial />
        <Footer company={companyGeneralData.company} />
      </>
    </>
  )
}

export async function getServerSideProps({ query }) {
  const adsLastOffers = await fetchGetAdsByCompany({
    pagination: { pageSize: 8, page: query.pag ?? 0 },
    filters: {},
    orderBy: 'LAST_VEHICLES'
  });
  const banners = await fetchGetBanners({ idMenuOption: 1 });

  return {
    props: {
      adsLastOffers,
      banners
    }
  }
}